import axios from 'axios';
import COEFFICIENT from "../utils";
import secureLocalStorage from "react-secure-storage";


export  const CheckDelegate = async (unit, setUserInfo) => {
    try {
        let response = await axios.get(`delegates/`)
        const delegates = response.data;
        let existDelegate = delegates.filter(delegate => (delegate.unit === unit|| delegate.name === unit))

        let userInfo = {
            coefficient: 0.0,
            groupAnswer: [],
            individualAnswer: []
        }

        if (existDelegate.length>0){


            let rootUnit = existDelegate[0].name
            if (rootUnit in COEFFICIENT){
                existDelegate = delegates.filter(delegate => (delegate.name === existDelegate[0].name))

                userInfo.coefficient= COEFFICIENT[rootUnit]
                userInfo.groupAnswer.push(rootUnit)
            } else{
                existDelegate = delegates.filter(delegate => (delegate.name === existDelegate[0].name))
            }


            existDelegate.map(delegate => {
                if (delegate.individualVote === true){
                    userInfo.individualAnswer.push(delegate.unit)
                } else{
                    userInfo.groupAnswer.push(delegate.unit)
                }

                userInfo.coefficient += COEFFICIENT[delegate.unit]
                return delegate
            })

            userInfo.individualAnswer.sort()
            userInfo.groupAnswer.sort()
        } else{
            userInfo.coefficient= COEFFICIENT[unit]
            userInfo.groupAnswer.push(unit)
        }
        setUserInfo(userInfo)
    } catch (error) {
        console.error(error);
    }
}

export const getQuestions = async (userInfo, setInfoQuestions) => {
    try {
        const response = await axios.get('questions/');
        let bellfortInfo = secureLocalStorage.getItem("bellfort");
        if (bellfortInfo === null){
            window.location.reload(false);
        }

        let activeQuestions = response.data.filter(question => (question.show === true))
        let result = []

        if (activeQuestions.length > 0) {
            activeQuestions.map(question => {
                let newInfoQuestion = {
                    id: question.id,
                                        text: question.text,
                    groupAnswer: userInfo.groupAnswer,
                    individualAnswer: userInfo.individualAnswer
                }
                if (question.id in bellfortInfo.answers) {

                    if (bellfortInfo.answers[question.id].complete) {
                        return question;
                    }

                    newInfoQuestion.groupAnswer = bellfortInfo.answers[question.id].groupAnswer;
                    newInfoQuestion.individualAnswer = bellfortInfo.answers[question.id].individualAnswer;
                }
                result.push(newInfoQuestion)
                return question
            })
        }
        setInfoQuestions(result);
    } catch (error) {
        console.error(error);
    }
};

export const login = async (id, setValidated, setter) => {
    try {
        const response = await axios.post('/login/', {"id": id});

        let unit = response.data.unit

        if (unit in COEFFICIENT){
            setValidated(false);
            secureLocalStorage.setItem("bellfort", {unit: unit, answers: {}});
            setter(id)
        } else {
            setValidated(true);
        }

    } catch (error) {
        console.error(error);
    }
};

