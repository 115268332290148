import React, {useEffect, useState} from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    Col,
    Container,
    Modal,
    Navbar,
    NavDropdown,
    Row,
    Spinner,
    Table
} from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import COEFFICIENT from "../utils";
import axios from "axios";
import {CheckDelegate, getQuestions} from "../api";
import logo from "../logo.png"
import bg from "../bg.png"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faFan} from "@fortawesome/free-solid-svg-icons";

const modalStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};


function Questions() {

    let bellfortInfo = secureLocalStorage.getItem("bellfort");
    const [infoQuestions, setInfoQuestions] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [modalQuestion, setModalQuestion] = useState({show: false, loading: true});

    const handleModalQuestionClose = () => setModalQuestion({...modalQuestion, show: false});
    const handleModalQuestionShow = () => setModalQuestion({loading: true, show: true});

    useEffect(() => {
        let bellfortInfo = secureLocalStorage.getItem("bellfort");
        CheckDelegate(bellfortInfo.unit, setUserInfo);
    }, []);


    useEffect(() => {
        const intervalId = setInterval(()=>{
            getQuestions(userInfo, setInfoQuestions);
        }, 10000);
        return () => clearInterval(intervalId);
    }, [userInfo]);


    function vote(questionID, unit, value){
        handleModalQuestionShow();

        if (Array.isArray(unit)){
            axios.post('/multiple_answer/', {"question": questionID, "value": value, "units": unit}).finally(res => {
                setTimeout(() => {
                    setModalQuestion({show: true, loading: false});
                    removeCardQuestion(questionID, unit);
                }, 2000)
            });

        } else {
            axios.post('/answer/', {"question": questionID, "value": value, "unit": unit}).finally(res => {
                setTimeout(() => {
                    setModalQuestion({show: true, loading: false});
                    removeCardQuestion(questionID, unit);
                }, 2000)
            });
        }
    }

    function logout(){
        secureLocalStorage.removeItem("bellfort");
        window.location.reload(false);
    }

    function removeCardQuestion(questionID, unit){
        const clon = [...infoQuestions]
        let newGroupAnswer = []
        let newIndividualAnswer = []
        let questionComplete = false
        let result = clon.map(info => {
            if (info.id === questionID){
                if (Array.isArray(unit)){
                    info.groupAnswer = []

                } else {
                    info.individualAnswer = info.individualAnswer.filter(individualUnit => (individualUnit !== unit))
                }
                newGroupAnswer = info.groupAnswer
                newIndividualAnswer = info.individualAnswer
            }
            questionComplete = !newGroupAnswer.length && !newIndividualAnswer.length
            if (questionComplete){
                return undefined
            }
            return info
        })

        let bellfortInfo = secureLocalStorage.getItem("bellfort");

        bellfortInfo.answers[questionID] = {
            groupAnswer: newGroupAnswer,
            individualAnswer: newIndividualAnswer,
            complete: questionComplete
        }

        secureLocalStorage.setItem("bellfort", bellfortInfo);
        setInfoQuestions(result.filter(question => question !== undefined))
    }

    function label(units){
        if (units.length ===1){
            return "Apto "+ units[0]
        } else if (units.length < 4){
            return "Aptos "+ units.join(", ")
        } else {
            return "Aptos "+ units.slice(0, 3).join(", ") + " y otros " + (units.length -3).toString();
        }
    }

    function getTotalCoefficient(unit){
        let result = 0
        if (Array.isArray(unit)){
            unit.map(u => {
                result += COEFFICIENT[u]
                return u
            })
        } else {
            result = COEFFICIENT[unit]
        }
        return Math.round(result * 100) / 100
    }
    return (
        <Container>
            <Navbar bg="light" variant="light" style={{
                backgroundImage: `linear-gradient(rgba(221,221,223, 0.5), rgba(221,221,223, 0.5)), url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
                <Container>
                    <Navbar.Brand>
                        <img alt="" src={logo} width="30" height="30" className="d-inline-block align-top"/>{' '}
                        Asamblea - Bellfort
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <NavDropdown title={bellfortInfo.unit} id="collasible-nav-dropdown">
                            <NavDropdown.Item onClick={logout}>Cerrar</NavDropdown.Item>
                        </NavDropdown>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container fluid style={{height: '90vh', display: 'flex', alignItems: 'center'}}>
                <Modal
                    show={modalQuestion.show}
                    onHide={handleModalQuestionClose}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    style={modalStyles}
                >
                    {modalQuestion.loading ? (
                        <div>
                            <Modal.Header >
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Modal.Title style={{textAlign: "center"}}>
                                        <FontAwesomeIcon icon={faFan} size="3x"  spin style={{ marginBottom: '10px' }}/>
                                        <div>Confirmando Respuesta</div>
                                    </Modal.Title>
                                </div>
                            </Modal.Header>
                        </div>
                    ):(
                        <div>
                            <Modal.Header >
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Modal.Title style={{textAlign: "center"}}>
                                        <FontAwesomeIcon icon={faCheckCircle} size="3x" color="green" style={{ marginBottom: '10px' }} />
                                        <div>Respuesta Confirmada</div>
                                    </Modal.Title>
                                </div>
                            </Modal.Header>
                            <Modal.Footer style={{justifyContent: "center"}}>
                                <Button variant="success" onClick={handleModalQuestionClose} size="lg" block>
                                    Cerrar
                                </Button>
                            </Modal.Footer>
                        </div>
                    )}
                </Modal>
                {infoQuestions.length === 0 ?
                    (
                        <div style={{textAlign: "center"}}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Cargando...</span>
                            </Spinner>
                            <h3 style={{textAlign: "center"}}>Actualmente no hay preguntas disponibles. Aquí se mostrarán una vez que estén habilitadas. ¡Gracias por tu paciencia!</h3>

                        </div>
                    ) :
                    (
                    infoQuestions.map((question, indexQuestion) => (
                        <div key={indexQuestion} style={{width: "100%"}}>
                            <h3 style={{textAlign: "center"}}>{question.text}</h3>
                            <br/>
                            <Row xs={1} md={2} xl={3} className="g-4 justify-content-md-center">
                                {question.groupAnswer.length !== 0 ? (
                                    <Col>
                                        <Card className="text-center">
                                            <Card.Header>{label(question.groupAnswer)}</Card.Header>
                                            <Card.Body>
                                                <Card.Text>
                                                    <Table striped bordered hover>
                                                        <tbody>
                                                        <tr>
                                                            <td>Coeficiente</td>
                                                            <td>{getTotalCoefficient(question.groupAnswer)}</td>
                                                        </tr>
                                                        </tbody>
                                                    </Table>
                                                </Card.Text>
                                                <ButtonGroup size="xl" style={{width: '100%'}}>
                                                    <Button onClick={() => vote(question.id, question.groupAnswer, 'A favor')} variant="success">A favor</Button>
                                                    <Button onClick={() => vote(question.id, question.groupAnswer, 'En contra')} variant="danger">En contra</Button>
                                                    <Button onClick={() => vote(question.id, question.groupAnswer, 'Abstencion')} variant="secondary">Abstencion</Button>
                                                </ButtonGroup>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ): (<div></div>)}
                                {question.individualAnswer.length !== 0 ? (question.individualAnswer.map((unit, indexDelegate) => (
                                    <Col key={indexDelegate}>
                                        <Card className="text-center">
                                            <Card.Header>Apto {unit}</Card.Header>
                                            <Card.Body>
                                                <Card.Text>
                                                    <Table striped bordered hover>
                                                        <tbody>
                                                        <tr>
                                                            <td>Coeficiente</td>
                                                            <td>{getTotalCoefficient(unit)}</td>
                                                        </tr>
                                                        </tbody>
                                                    </Table>
                                                </Card.Text>
                                                <ButtonGroup size="xl"  style={{width: '100%'}} >
                                                    <Button onClick={() => vote(question.id, unit,'A favor')} variant="success">A favor</Button>
                                                    <Button onClick={() => vote(question.id, unit,'En contra')} variant="danger">En contra</Button>
                                                    <Button onClick={() => vote(question.id, unit,'Abstencion')} variant="secondary">Abstencion</Button>
                                                </ButtonGroup>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))): (<div></div>)}
                            </Row>
                            <hr />
                            <p style={{textAlign: "right"}}>Coeficiente total {Math.round(userInfo.coefficient * 100) / 100}</p>
                        </div>
                        )
                    )
                    )
                }
            </Container>
        </Container>
    )
}

export default Questions