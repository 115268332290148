import './App.css';
import axios from 'axios';
import React, {useEffect, useState} from "react";
import Login from "./components/login";
import Questions from "./components/questions";
import secureLocalStorage from "react-secure-storage";
import {Container} from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

let dns = window._env_.dns;

function App() {
    axios.defaults.baseURL = `${dns}`;

    const [apartament, setApartament] = useState(null);

    useEffect(() => {
        var bellfortInfo = secureLocalStorage.getItem("bellfort");
        if (bellfortInfo != null) {
            setApartament(bellfortInfo.unit);
        }
    }, []);

    return (
        <Container>
            {apartament === null ? (<Login setter={setApartament}/>) : (<Questions/>)}
        </Container>
    );
}

export default App;
