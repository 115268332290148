import React, {useState} from 'react';
import {Button, Col, Form, Row, Image, FloatingLabel, Container} from 'react-bootstrap';
import logo from "../logo.png"
import {login} from "../api";

function Login(props) {
    const [id, setId] = useState('');
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!isNaN(id) && !isNaN(Number(id))) {
            login(id, setValidated, props.setter)
        }else {
            setValidated(true);
        }
    }
    const handleChange = (event) => {
        setId(event.target.value);
    }

    return (
        <Container fluid style={{height: '100vh', display: 'flex', alignItems: 'center'}}>
            <Form onSubmit={handleSubmit}
                  style={{height: '100vh', display: 'flex', alignItems: 'center', width: "100%"}}>
                <Form.Group as={Row} controlId="formLogin" style={{width: "100%"}}>
                    <Col md={{span: 4, offset: 4}}>
                        <Image src={logo} style={{
                            width: '200px',
                            height: '200px',
                            backgroundColor: 'white',
                            display: 'block',
                            margin: 'auto'
                        }}/>
                        <h4 style={{textAlign: "center"}}>Asamblea 16 Marzo 2024</h4>
                        <br/>
                        <FloatingLabel controlId="floatingInput" label="Documento" className="mb-3"
                                       style={{textAlign: "center"}}>
                            <Form.Control isInvalid={validated}
                                          type="number"
                                          placeholder="Documento"
                                          value={id}
                                          onChange={handleChange}
                                          required
                            />
                        </FloatingLabel>
                        <Form.Control.Feedback type="invalid">Documento invalido</Form.Control.Feedback>
                        <Button variant="success" type="submit" style={{width: '100%'}} >
                            Entrar
                        </Button>
                    </Col>
                </Form.Group>
            </Form>
        </Container>
    )
}

export default Login